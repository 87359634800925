import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image/withIEPolyfill'
import React from 'react'
import InViewMonitor from 'react-inview-monitor'

import useLocalisation from '../../helper/useLocalisation'
import Link from '../Link'

const FadeUp = ({ children, delay }) => {
  let delayClass = ''
  if (delay !== undefined) {
    delayClass = `delay-c-${delay}s`
  }
  return (
    <InViewMonitor
      classNameNotInView="vis-hidden"
      classNameInView={`animate__animated animate__fadeInUp ${delayClass}`}
    >
      {children}
    </InViewMonitor>
  )
}

const Fade = ({ children, delay }) => {
  let delayClass = ''
  if (delay !== undefined) {
    delayClass = `delay-c-${delay}s`
  }
  return (
    <InViewMonitor
      classNameNotInView="vis-hidden"
      classNameInView={`animate__animated animate__fadeIn ${delayClass}`}
    >
      {children}
    </InViewMonitor>
  )
}

// Explore
const ExploreContainer = styled.div`
  padding-bottom: 64px;
  background: rgb(226, 220, 214);
`
const ExploreHeaderContainer = styled.div`
  height: 220px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1025px) {
    margin-top: 8px;
    height: 100px;
  }
`
const ExploreHeading = styled.h2`
  font-size: 40px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: -1.17px;
  text-align: center;
  color: #1e3d3c;
  @media (max-width: 1025px) {
    font-size: 21px;
  }
`

const PanelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 200px);
  @media (max-width: 1025px) {
    flex-direction: column;
    height: auto;
  }
`

const ExploreText = styled.div`
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -1.17px;
  text-align: center;
  color: #ffffff;
  z-index: 999;
  @media (max-width: 1025px) {
    font-size: 21px;
  }
`
const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: all 0.2s;
  transform: scale(1);
  filter: blur(0px);
  &:hover {
    transform: scale(1.1);
    filter: blur(4px);
  }
`
const ExploreImage = styled(Image)`
  height: 100%;
  width: 100%;
`

const ExplorePanel = ({ image, copy }) => {
  const ExplorePanel = styled.div`
    user-select: none;
    width: 373px;
    height: 474px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 20px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    @media (max-width: 1025px) {
      width: calc(100vw - 40px);
      height: 227px;
    }
  `
  return (
    <ExplorePanel>
      <ImageContainer>
        <ExploreImage objectFit="cover" fluid={image.childImageSharp.fluid} />
      </ImageContainer>
      <ExploreText> {copy} </ExploreText>
    </ExplorePanel>
  )
}

const ExploreSection = () => {
  const imageData = useStaticQuery(graphql`
    query {
      carry: file(relativePath: { eq: "trunk/landing/collection-carry.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      checked: file(
        relativePath: { eq: "trunk/landing/collection-checked.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  const Q = useLocalisation()
  return (
    <ExploreContainer>
      <ExploreHeaderContainer>
        <FadeUp>
          {' '}
          <ExploreHeading> Shop the Trunk Collection. </ExploreHeading>{' '}
        </FadeUp>
      </ExploreHeaderContainer>
      <PanelContainer>
        <Fade>
          {' '}
          <Link to={'/luggage/carry-on-trunk'}>
            <ExplorePanel copy={'Carry On'} image={imageData.carry} />
          </Link>
        </Fade>
        <Fade delay={1}>
          <Link to={'/luggage/checked-trunk'}>
            <ExplorePanel copy={'Checked'} image={imageData.checked} />
          </Link>
        </Fade>
      </PanelContainer>
    </ExploreContainer>
  )
}

export default ExploreSection
